<template>
	<div class="houselistbox">
			<div class="transformer">
				<div class="clearfix electric-select">
					<el-form ref="ruleForm" :inline="true">
						<el-form-item>
							<el-button type="primary" @click="add()">
								<i class="el-icon-plus"></i>
								新增
							</el-button>
						</el-form-item>
					</el-form>
				</div>
				<div v-loading="loading1">
					<el-table ref="multipleTable" :data="tableData" border :row-key="getRowKeys">
						<el-table-column prop="tvalue" label="专项描述" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column align="center" label="操作">
							<template slot-scope="scope">
								<el-button @click="deleteFn(scope.row)" type="danger" size="mini">删除</el-button>
							</template>
						</el-table-column>
					</el-table>

					<div>
						<div style="text-align: right; margin-top: 40px; padding: 0 20px;">
							<el-button @click="cancle" size="medium" type="primary">关 闭</el-button>
						</div>
					</div>
				</div>
			</div>

			<el-dialog
				width="30%"
				title="新增"
				:visible.sync="innerVisible"
				append-to-body>
				<el-form :model="form">
					<el-form-item label="专项描述" :label-width="formLabelWidth">
						<el-input v-model="form.desc" autocomplete="off"></el-input>
					</el-form-item>
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button @click="innerVisible = false">取 消</el-button>
					<el-button type="primary" @click="submitFn">确 定</el-button>
				</div>
			</el-dialog>


	</div>
</template>

<script>
	import {
		getLogIdearType,
		logIdearDel,
		logIdearAdd
	} from '@/api/ninesite'
	export default {
		data() {
			return {
				tableData: [],
				loading1: false,
        innerVisible: false,
        form: {
          desc: ''
        },
        formLabelWidth: '120px'
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			cancle() {
				this.$emit('closeZhuanxiangDialog')
			},
			getRowKeys(row) {
				return row.uuid
			},
			add(){
				this.innerVisible = true;
			},
			submitFn(){
				if(!this.form.desc.trim()){
					this.$message.warning('请输入专项描述');
					return false;
				}
				this.innerVisible = false
				logIdearAdd({
						userid: this.GLOBAL.adminId(),
						tvalue: this.form.desc,
					}).then(res => {
						this.$message.success('操作成功');
						this.getList();
						this.form.desc = '';
					})
			},
			deleteFn(row){
				this.$confirm('是否确认删除该条专项巡防项?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					logIdearDel({
						userid: this.GLOBAL.adminId(),
						id: row.id,
					}).then(res => {
						this.$message.success('操作成功');
						this.getList();
					})
				}).catch(() => {

				});
			},
			getList() {
				var that = this;
				that.loading1 = true;
				getLogIdearType().then((res) => {
					that.loading1 = false;
					if (res.result == '200') {
						that.tableData = res.detail;
					} else {
						that.$message.error(res.description);
					}
				})
			},
		}
	};
</script>

<style lang="scss" scoped="scoped">
	.block {
		padding: 20px 0 0;
	}
</style>