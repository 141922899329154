<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="店铺名称">
					<el-input v-model="formInline.showname" clearable placeholder="店铺名称"></el-input>
				</el-form-item>
				<el-form-item label="负责人">
					<el-input v-model="formInline.head" clearable placeholder="负责人"></el-input>
				</el-form-item>
				<el-form-item label="巡防次数">
					<el-input v-model="formInline.totalcount" type="number" clearable placeholder="巡防次数"></el-input>
				</el-form-item>
				<el-form-item label="问题数">
					<el-input v-model="formInline.problemcount" type="number" clearable placeholder="问题数"></el-input>
				</el-form-item>
				<el-form-item label="巡防地址">
					<el-input v-model="formInline.address" clearable placeholder="巡防地址"></el-input>
				</el-form-item>
				<!-- <el-form-item label="巡防周期">
					<el-select v-model="formInline.period" clearable placeholder="巡防周期">
						<el-option v-for="opt in periodOptions" :key="opt.value"
							:label="opt.label + ' / ' + opt.tip" :value="opt.value"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="场所类型">
					<el-select v-model="formInline.tcode" clearable placeholder="场所类型">
						<el-option v-for="opt in nineSiteTypeOptions" :key="opt.tcode"
							:label="opt.tvalue" :value="opt.tcode"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="专项巡防">
					<el-select v-model="formInline.idername" clearable placeholder="专项巡防">
						<el-option v-for="opt in zhuangxiangXunfangOptions" :key="opt.tvalue"
							:label="opt.tvalue" :value="opt.tvalue"></el-option>
					</el-select>
				</el-form-item>
				
				<el-form-item label="巡防状态">
					<el-select v-model="formInline.periodStatus" clearable placeholder="巡防状态">
						<el-option v-for="opt in periodStatusOptions" :key="opt.value" :label="opt.label"
							:value="opt.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="告警状态">
					<el-select v-model="formInline.warnstatus" clearable placeholder="告警状态">
						<el-option v-for="opt in warnStatusOptions" :key="opt.value" :label="opt.label"
							:value="opt.value"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="search"><i class="el-icon-search"></i> 查询</el-button>
					<el-button type="primary" @click="addZhuanxiang"><i class="el-icon-plus"></i> 新增专项巡防项</el-button>
					<!-- <el-button type="primary" @click="addTask"><i class="el-icon-plus"></i> 新增巡防任务</el-button> -->
					<el-button @click="exportList" type="primary" size="mini" :loading="exportLoading">导出</el-button>
					<el-button @click="exportFlowList" type="primary" size="mini" :loading="exportLoading">流水导出</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="contbox">
			<div class="leftcont">
				<el-table :data="tableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
					:row-style="{ fontSize: '12px', color: '#6a6a6a'}" :height="tableHeight" border style="width: 100%">
					<!-- <el-table-column height show-overflow-tooltip width="120px" align="center" label="任务状态">
						<template slot-scope="scope">
							<span v-if="scope.row.task_status == 0">进行中</span>
							<span v-if="scope.row.task_status == 1" style="color: red;">终止</span>
						</template>
					</el-table-column> -->
					<!-- <el-table-column height show-overflow-tooltip width="120px" align="center" prop="alarm_status" label="告警状态">
						<template slot-scope="scope">
							<span v-if="scope.row.alarm_status == 0">正常</span>
							<span v-if="scope.row.alarm_status == 1" style="color: #E6A23C;">超时</span>
							<span v-if="scope.row.alarm_status == 2" style="color: red;">终止</span>
						</template>
					</el-table-column> -->
					<el-table-column height show-overflow-tooltip align="center" prop="showname" label="店铺名称">
					</el-table-column>
					<el-table-column height show-overflow-tooltip align="center" prop="name" label="负责人">
					</el-table-column>
					<el-table-column height show-overflow-tooltip align="center" prop="ninetypename" label="场所类型" width="120px">
					</el-table-column>
					<el-table-column height show-overflow-tooltip align="center" prop="childninetype" label="场所小类型" width="120px">
					</el-table-column>
					<el-table-column height show-overflow-tooltip align="center" prop="address" label="巡防地址">
					</el-table-column>
					
					<!-- <el-table-column height show-overflow-tooltip width="120px" align="center" prop="period" label="巡防周期"
						:formatter="(row) => formatterStatus(row.period, periodOptions)">
					</el-table-column> -->
					<!-- <el-table-column height show-overflow-tooltip width="120px" align="center" prop="period_status" label="完成情况"
						:formatter="(row) => formatterStatus(row.period_status, periodStatusOptions)">
					</el-table-column> -->
					<el-table-column height show-overflow-tooltip align="center" prop="totalcount" label="累计巡防次数" width="120px">
					</el-table-column>
					<el-table-column height show-overflow-tooltip align="center" prop="problemcount" label="发现问题数" width="120px">
					</el-table-column>
					<el-table-column align="center" label="操作">
						<template slot-scope="scope">
							<!-- <el-button @click="promptingClick(scope.row)" type="primary" size="mini">督促</el-button> -->
							<!-- <el-button v-if="scope.row.task_status == 0" @click="pauseClick(scope.row)" type="warning"
								size="mini">删除</el-button> -->
							<el-button @click="viewClick(scope.row)" type="success" size="mini">查看</el-button>
							<el-button @click="exportInfo(scope.row)" type="primary" size="mini">导出</el-button>
						</template>
					</el-table-column>
				</el-table>

				<div class="pagebox">
					<el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[10, 20, 50, 100]" :page-size="pageSize"
						layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
					</el-pagination>
				</div>

			</div>
			<!-- <div class="rightcont">
				<div class="tit">任务情况</div>
				<div class="cont">
					<div class="tip"><img src="../../assets/imgs/task/task.png" alt="总任务">总任务：{{totalRow}}个</div>
					<div class="chart" id="task" style="height: 240px;">

					</div>
				</div>
				<div class="cont">
					<div class="tip"><img src="../../assets/imgs/task/time.png"
							alt="任务周期">任务周期：{{ taskPeriodData.totalcount }}个</div>
					<div class="chart" id="time" style="height: 200px;">

					</div>
				</div>
			</div> -->
		</div>

		<el-dialog title="新增巡防任务" :close-on-click-modal="false" :show-close="false" :destroy-on-close="true"
			:visible.sync="dialogFormVisible" width="900px">
			<div class="formbox">
				<el-form :model="form" :rules="rules" ref="form">
					<el-form-item label="巡防周期" :label-width="formLabelWidth" prop="period">
						<el-select v-model="form.period" clearable placeholder="巡防周期" style="width: 300px;">
							<el-option v-for="opt in periodOptions" :key="opt.value"
								:label="opt.label + ' / ' + opt.tip" :value="opt.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="巡防地址" :label-width="formLabelWidth" prop="houseIds">
						<el-button type="primary" plain @click="addAddress"><i class="el-icon-plus"></i> 新增</el-button>
						<el-input style="opacity: 0; width:100px;" v-model="form.houseIds" clearable
							placeholder="负责人"></el-input>
					</el-form-item>
				</el-form>
				<div style="padding-left: 30px;">
					<el-table :data="addressTableData" :header-cell-style="{  fontSize: '14px', color: '#282828'}"
						:row-style="{ fontSize: '12px', color: '#6a6a6a'}">
						<el-table-column prop="showname" label="店铺名称" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="ninetypename" label="场所类型" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="siteman" label="店铺管理人" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="sitemanphone" label="管理人电话" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column prop="managename" label="社区管理员" align="center" height
							show-overflow-tooltip>
						</el-table-column>
						<el-table-column align="center" fixed="right" label="操作">
							<template slot-scope="scope">
								<el-button size="mini" type="danger" plain
									@click="deleteClick(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="medium" @click="resetForm('form')">取 消</el-button>
				<el-button size="medium" type="primary" @click="addPatrolTask('form')">确 定</el-button>
			</div>
		</el-dialog>

		<el-dialog title="添加地址" :close-on-click-modal="false" :destroy-on-close="true" :show-close="false"
			:visible.sync="addressVisible" width="1100px">
			<house-list-cpn @getAddressInfo="getAddressInfo" @closeAddressDialog="closeAddressDialog"></house-list-cpn>
		</el-dialog>

		<el-dialog title="专项巡防项" :close-on-click-modal="false" :destroy-on-close="true" :show-close="false"
			:visible.sync="zhuanxiangVisible" width="800px">
			<zhuanxiang-cpn v-if="zhuanxiangVisible" @getAddressInfo="getAddressInfo" @closeZhuanxiangDialog="closeZhuanxiangDialog"></zhuanxiang-cpn>
		</el-dialog>

		

		<el-dialog append-to-body title="巡防详情" :close-on-click-modal="false" :visible.sync="detailVisible"
			width="1000px" @closed="detailTaskId = null">
			<detail-cpn :taskId="detailTaskId"></detail-cpn>
		</el-dialog>

	</div>
</template>

<script>
	import houseListCpn from './houseList'
	import detailCpn from './detail'
	import zhuanxiangCpn from './zhuanxiangCpn.vue'
	import * as FileSaver from 'file-saver'
	import {
		patrolTaskList,
		taskTotal,
		taskPeriod,
		addTask,
		taskEnd,
		patrolTaskExport,
		nineSiteTaskType,
		getLogIdearType,
		exportNineSiteList,
		exportLogList
	} from '@/api/ninesite'
	import {
		taskStatusOptions,
		warnStatusOptions,
		periodStatusOptions,
		periodOptions
	} from './dict'

	export default {
		name: "",
		components: {
			houseListCpn,
			detailCpn,
			zhuanxiangCpn
		},
		props: {},
		data() {
			return {
				formInline: {
					head: '',
					address: '',
					periodStatus: '',
					warnstatus: '',
					showname:'',
					idername: '',
					tcode: '',
					totalcount: '',
					problemcount: ''
				},
				tableData: [],
				addressTableData: [],
				taskChart: null,
				timeChart: null,
				currentPage: 1,
				pageSize: 10,
				totalRow: 0,
				tableHeight: 0,
				dialogFormVisible: false,
				form: {
					period: '',
					houseIds: ''
				},
				rules: {
					period: [{
						required: true,
						message: '请选择巡防周期',
						trigger: 'change'
					}],
					houseIds: [{
						required: true,
						message: '请选择巡防地址',
						trigger: 'change'
					}],
				},
				formLabelWidth: '100px',
				taskStatusOptions,
				warnStatusOptions,
				periodStatusOptions,
				periodOptions,
				taskPeriodData: {},
				finishTotal: [],
				unfinishTotal: [],
				addressVisible: false,
				detailVisible: false,
				detailTaskId: null,
				nineSiteTypeOptions: [],
				zhuangxiangXunfangOptions: [],
				zhuanxiangVisible: false,
				exportLoading: false
			}
		},
		watch: {},
		created() {
			this.getHeight();
		},
		mounted() {
			this.getList();
			// this.getTastTotal();
			// this.getTaskPeriod();
			//挂载window.onresize事件
			window.onresize = () => {
				this.getHeight();
			}
			this.getNineSiteType();
			this.getZhuangxiangXunfang();
		},
		//注销window.onresize事件
		destroyed() {
			window.onresize = null;
		},
		computed: {},
		methods: {
			//场所类型
			getNineSiteType(){
				nineSiteTaskType().then(res => {
					if (res.result == 200) {
						this.nineSiteTypeOptions = res.detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//专项巡防
			getZhuangxiangXunfang(){
				getLogIdearType().then(res => {
					if (res.result == 200) {
						this.zhuangxiangXunfangOptions = res.detail;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//关闭专项巡防
			closeZhuanxiangDialog(){
				this.zhuanxiangVisible = false;
				this.getZhuangxiangXunfang();
			},

			//子组件
			getAddressInfo(val) {
				this.addressVisible = false;
				this.addressTableData = val;

				let houseIdsArr = [];
				this.addressTableData.map(item => {
					houseIdsArr.push(item.uuid)
				})
				this.form.houseIds = houseIdsArr.length ? houseIdsArr.join(',') : '';
			},
			closeAddressDialog(val) {
				this.addressVisible = false;
			},
			//总任务
			getTaskPeriod() {
				taskPeriod().then(res => {
					if (res.result == 200) {
						this.taskPeriodData = res.detail;
						this.setTimeChart();
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//任务周期
			getTastTotal() {
				taskTotal().then(res => {
					if (res.result == 200) {
						let {
							finishcount,
							unfinishcount,
							alarmcount,
							processingcount
						} = res.detail;
						this.finishTotal = [{
								value: finishcount,
								name: '已完成'
							},
							{
								value: unfinishcount,
								name: '未完成'
							}
						]
						this.unfinishTotal = [{
								value: alarmcount,
								name: '告警数'
							},
							{
								value: processingcount,
								name: '正常数'
							}
						]
						this.setTaskChart();
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//巡防任务列表
			getList() {
				patrolTaskList({
					gridman: this.formInline.head,
					address: this.formInline.address,
					idername: this.formInline.idername,
					periodStatus: this.formInline.periodStatus,
					alarmStatus: this.formInline.warnstatus,
					showname: this.formInline.showname,
					period: this.formInline.period,
					tcode: this.formInline.tcode,
					totalcount: this.formInline.totalcount,
					problemcount: this.formInline.problemcount,
					page: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.result == 200) {
						this.tableData = res.detail.list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//设置表格高度
			getHeight() {
				let clientHeight = document.body.clientHeight;
				this.tableHeight = clientHeight - 260;
			},
			//总任务图表
			setTaskChart() {
				if (!this.taskChart) {
					this.taskChart = this.$echarts.init(document.getElementById('task'));
				}
				// 绘制图表
				this.taskChart.setOption({
					tooltip: {
						trigger: 'item',
						formatter: '{a} <br/>{b}: {c} ({d}%)'
					},
					color: ['#2aacdd', '#f58c2e', '#f2a120', '#13b975'],
					series: [{
							name: '任务情况',
							type: 'pie',
							radius: [0, '35%'],
							label: {
								position: 'inner',
								color: '#fff'
							},
							labelLine: {
								show: false
							},
							data: this.finishTotal
						},
						{
							name: '告警情况',
							type: 'pie',
							radius: ['55%', '75%'],
							data: this.unfinishTotal
						}
					]
				}, true);
			},
			//任务周期图表
			setTimeChart() {
				if (!this.timeChart) {
					this.timeChart = this.$echarts.init(document.getElementById('time'));
				}
				// 绘制图表
				this.timeChart.setOption({
					tooltip: {
						trigger: 'item'
					},
					xAxis: {
						type: 'value',
						splitLine: {
							show: false
						},
						axisLabel: {
							show: false
						},
						axisTick: {
							show: false
						}
					},
					grid: {
						left: '3%',
						right: '10%',
						bottom: '3%',
						top: '3%',
						containLabel: true
					},
					yAxis: {
						type: 'category',
						data: ['按周', '按月', '按季度', '按年'],
						inverse: true,
						axisTick: {
							show: false
						},
						axisLine: {
							lineStyle: {
								color: '#d2d2d2'
							}
						},
						axisLabel: {
							color: '#282828',
							fontSize: 14
						}
					},
					color: ['#73b5f9'],
					series: [{
						data: this.taskPeriodData ? [this.taskPeriodData.weekcount, this.taskPeriodData
							.monthcount, this.taskPeriodData.seasoncount, this.taskPeriodData
							.yearcount,
						] : [],
						type: 'bar',
						barWidth: 20,
						itemStyle: {
							borderRadius: [0, 10, 10, 0]
						},
						label: {
							show: true,
							position: 'right',
							color: '#73b5f9'
						},
					}]
				}, true);
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//查询
			search() {
				this.currentPage = 1;
				this.getList();
			},
			//打开新增巡防任务弹框
			addTask() {
				this.dialogFormVisible = true;
			},
			//新增专项巡防项
			addZhuanxiang(){
				this.zhuanxiangVisible = true;
			},

			//新增巡防任务
			addPatrolTask(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.$confirm('是否确认新增巡防任务, 是否继续?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							addTask({
								siteIds: this.form.houseIds,
								period: this.form.period,
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('操作成功')
									this.search();
									this.resetForm(formName);
								} else {
									this.$message.warning(res.description)
								}
							})
						}).catch(() => {
							console.log(123);
						});
						
					} else {
						return false;
					}
				});
				
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
				this.addressTableData = [];
			},
			//查看
			viewClick(row) {
				console.log(row)
				this.detailVisible = true;
				this.detailTaskId = row.uuid;
			},
			//流水导出
			exportFlowList(){
				this.exportLoading = true;
				exportLogList({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					gridman: this.formInline.head,
					address: this.formInline.address,
					idername: this.formInline.idername,
					periodStatus: this.formInline.periodStatus,
					alarmStatus: this.formInline.warnstatus,
					showname: this.formInline.showname,
					period: this.formInline.period,
					tcode: this.formInline.tcode,
					totalcount: this.formInline.totalcount,
					problemcount: this.formInline.problemcount,
					page: this.currentPage,
					pageSize: 999999
				}).then((res) => {
					this.exportLoading = false;
					if (res.result == '200') {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
					} else {
						this.$message.error(res.description);
					}
				}).catch(err=>{
					this.exportLoading = false;
					this.$message.error(err);
				})
			},
			//导出列表
			exportList(){
				this.exportLoading = true;
				exportNineSiteList({
					'x-access-token': this.GLOBAL.token(),
					"userid": this.GLOBAL.adminId(),
					gridman: this.formInline.head,
					address: this.formInline.address,
					idername: this.formInline.idername,
					periodStatus: this.formInline.periodStatus,
					alarmStatus: this.formInline.warnstatus,
					showname: this.formInline.showname,
					period: this.formInline.period,
					tcode: this.formInline.tcode,
					totalcount: this.formInline.totalcount,
					problemcount: this.formInline.problemcount,
					page: this.currentPage,
					pageSize: 999999
				}).then((res) => {
					this.exportLoading = false;
					if (res.result == '200') {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
					} else {
						this.$message.error(res.description);
					}
				}).catch(err=>{
					this.exportLoading = false;
					this.$message.error(err);
				})
			},
			//导出一条数据
			exportInfo(row) {
				console.log(row)
				this.$confirm('是否确认导出该条任务的巡防流水?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					patrolTaskExport({
						ninesiteuuid: row.ninesiteuuid,
						idername: this.formInline.idername
					}).then(res => {
						console.log(res);
            FileSaver.saveAs(res.detail, res.detail.split('/downfile/')[1]);
					})
				}).catch(() => {

				});
			},
			//删除
			pauseClick(row) {
				this.$confirm('是否确认删除该任务?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					taskEnd({
						id: row.uuid
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功');
							this.search();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(() => {

				});
			},
			addAddress() {
				this.addressVisible = true;
			},
			//督促
			promptingClick(row) {

			},
			//删除
			deleteClick(row) {
				var index = this.addressTableData.indexOf(row)
				if (index !== -1) {
					this.addressTableData.splice(index, 1)
				}

				let houseIdsArr = [];
				this.addressTableData.map(item => {
					houseIdsArr.push(item.houseid)
				})
				this.form.houseIds = houseIdsArr.length ? houseIdsArr.join(',') : '';

			},
			/**
			 * 字段格式化
			 * @param {Number|String} value
			 * @param {Array} list
			 * @return {String}
			 */
			formatterStatus(value, list) {
				if (value === "" || value == undefined) return "";
				const item = list.find((item) => item.value == value);
				return item ? item.label : "";
			}

		}
	}
</script>

<style lang="scss" scoped>
	.contbox {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.leftcont {
			// width: calc(100% - 350px);
			width: calc(100% );
		}

		.rightcont {
			width: 335px;
			border: 1px solid #e5e5e5;

			.tit {
				color: #5393f3;
				font-size: 14px;
				background: #ecf4fc;
				line-height: 30px;
				padding-left: 15px;
			}

			.cont {
				padding: 14px;

				&+.cont {
					position: relative;
					padding-top: 40px;

					&::after {
						width: calc(100% - 28px);
						height: 5px;
						background: #f6f6f6;
						content: "";
						position: absolute;
						left: 14px;
						top: 0;
					}
				}

				.tip {
					color: #282828;
					font-size: 19px;
					display: flex;
					align-items: center;

					img {
						margin-right: 5px;
					}
				}

				.chart {
					margin: 20px 0 30px;
				}
			}
		}

		.pagebox {
			padding: 20px 0;
		}
	}

	.formbox {
		width: 94%;
	}
</style>